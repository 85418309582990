import "./styles/App.css";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Outlet, useLocation } from "react-router-dom";

// components
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import ReturnToTop from "./components/ReturnToTop";

const App = () => {
  const location = useLocation();
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  return (
    <div className="app">
      <Navbar />
      {location.pathname !== "/" ? <ReturnToTop /> : ""}
      <main id="main">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default App;
