import React, { useCallback, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Gallery from "react-photo-gallery";
import { photoCategories } from "../../data/photoCategories";
import { useWidth } from "../../utils/hooks";
import MotionImage from "./MotionImage";
import ParallaxImageHeader from "../ParallaxImageHeader";

const PhotoCategory = ({ category }) => {
  const width = useWidth();
  const location = useLocation();
  const [vertical, setVertical] = useState(width < 900);

  const fetchCategory = () => {
    const cat = photoCategories.find((cat) => cat.title === category);
    return cat;
  };

  const [cat, setCat] = useState(fetchCategory());

  useEffect(() => {
    if (vertical && width > 900) {
      setVertical(false);
    } else if (!vertical && width < 900) {
      setVertical(true);
    }
  }, [width]);

  useEffect(() => {
    setCat(fetchCategory());
  }, [location]);

  const handleColumns = () => {
    if (width < 600) {
      return 2;
    } else {
      return 3;
    }
  };

  const imageRenderer = useCallback(
    ({ index, left, top, key, photo, margin, direction }) => (
      <MotionImage
        selected={false}
        key={key}
        margin={margin}
        direction={direction}
        index={index}
        photo={photo}
        left={left}
        top={top}
      />
    ),
    []
  );

  return (
    <div className="photo-category-page">
      <ParallaxImageHeader data={cat} />
      <Gallery
        photos={cat.photos}
        direction={"column"}
        columns={handleColumns}
        margin={5}
        renderImage={imageRenderer}
      />
    </div>
  );
};

export default PhotoCategory;
