import { couplesPhotos } from "./cloudinary/couples";
import { gradPhotos } from "./cloudinary/grad";
import { familyPhotos } from "./cloudinary/family";
import { portraitPhotos } from "./cloudinary/portraits";

export const photoCategories = [
  {
    title: "couples",
    subtitle: "cherishing the people you love",
    cover:
      "https://res.cloudinary.com/dixemyxo3/image/upload/v1682995045/portfolio/couples/km-cover2_sxq8ns.jpg",
    mobileCover:
      "https://res.cloudinary.com/dixemyxo3/image/upload/v1682993293/portfolio/couples/km-cover_pcecys.jpg",
    mobileWidth: 1100,
    photos: couplesPhotos,
  },
  {
    title: "graduation",
    subtitle: "commemorating your academic achievements",
    cover:
      "https://res.cloudinary.com/dixemyxo3/image/upload/v1682993312/portfolio/grad/ss-cover_ttxyvw.jpg",
    mobileCover:
      "https://res.cloudinary.com/dixemyxo3/image/upload/v1682993312/portfolio/grad/es-cover_b3fie0.jpg",
    photos: gradPhotos,
  },
  {
    title: "family",
    subtitle: "capturing the heart of your loved ones",
    cover:
      "https://res.cloudinary.com/dixemyxo3/image/upload/v1714173489/portfolio/family/cfam-5_b6euav.jpg",
    mobileCover:
      "https://res.cloudinary.com/dixemyxo3/image/upload/v1695257842/portfolio/family/wf-8_iscxwc.jpg",
    photos: familyPhotos,
  },
  {
    title: "portraits",
    subtitle: "celebrating & empowering you",
    cover:
      "https://res.cloudinary.com/dixemyxo3/image/upload/v1650571497/portfolio/portraits/ej-2_y5cz6a.jpg",
    mobileCover:
      "https://res.cloudinary.com/dixemyxo3/image/upload/v1682993339/portfolio/portraits/ej-cover_esvedv.jpg",
    photos: portraitPhotos,
  },
];
