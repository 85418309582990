export const gradPhotos = [
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646259463/portfolio/grad/mk-2_tvslrt.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646259469/portfolio/grad/mk-1_cxhnss.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646259464/portfolio/grad/mk-3_uierlr.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646259469/portfolio/grad/mk-4_bogxvk.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646259469/portfolio/grad/es-1_pcxkhx.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646259469/portfolio/grad/es-3_m48n8f.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646259468/portfolio/grad/es-2_iameyb.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646259464/portfolio/grad/es-5_r2wb1q.jpg",
    width: 2,
    height: 3,
  },

  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646259466/portfolio/grad/edd-1_aogowi.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646350835/portfolio/grad/sb-1_kcxxum.jpg",
    width: 1,
    height: 1,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646350836/portfolio/grad/ss-3_wkjwf3.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646259467/portfolio/grad/ss-1_zyusmw.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646350836/portfolio/grad/gk-1_awhpva.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646259465/portfolio/grad/e-1_lhwgp4.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646350835/portfolio/grad/do-2_gevsih.jpg",
    width: 4,
    height: 5,
  },

  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646259463/portfolio/grad/do-3_uzmlcn.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646259461/portfolio/grad/do-1_cvffrv.jpg",
    width: 4,
    height: 5,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646259467/portfolio/grad/bmkm-3_x26kwq.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646259467/portfolio/grad/bmkm-5_zx4xlp.jpg",
    width: 5,
    height: 4,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646259469/portfolio/grad/bmkm-4_tmlq4c.jpg",
    width: 4,
    height: 5,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646259467/portfolio/grad/bmkm-1_lkdkvs.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646259465/portfolio/grad/bmkm-2_kfz1c6.jpg",
    width: 16,
    height: 10,
  },
];
