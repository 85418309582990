import React from "react";
import { motion } from "framer-motion";

const MotionImage = ({ index, photo, margin, top, left, direction }) => {
  const styles = {
    overflow: direction === "column" ? "hidden" : "",
    position: direction === "column" ? "absolute" : "",
    left: left,
    top: top,
  };

  return (
    <motion.img
      initial={{ opacity: 0, y: "25px" }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, amount: "25%" }}
      transition={{ duration: 1 }}
      style={{ margin, height: photo.height, width: photo.width, ...styles }}
      alt={photo.title}
      {...photo}
    />
  );
};

export default MotionImage;
