import { useScroll } from "../utils/hooks";
import { useWidth } from "../utils/hooks";
import { motion } from "framer-motion";

const ParallaxImageHeader = ({ data }) => {
  const scrollY = useScroll();
  const width = useWidth();
  let { cover, mobileCover, mobileWidth, title, subtitle } = data;
  if (!mobileWidth) {
    mobileWidth = 900;
  }

  return (
    <motion.div
      className="parallax-image-header"
      style={{
        backgroundPosition: `50% ${scrollY * 0.3}px`,
        backgroundImage: `url(${width < mobileWidth ? mobileCover : cover})`,
      }}
    >
      <div className="overlay">
        <header className="page-header">
          <h1 className="page-title">{title}</h1>
          <h2 className="page-subtitle">{subtitle}</h2>
        </header>
      </div>
    </motion.div>
  );
};

export default ParallaxImageHeader;
