export const portraitPhotos = [
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646354578/portfolio/portraits/jw_wqwawv.jpg",
    width: 4,
    height: 5,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646354577/portfolio/portraits/jd_zmgjtj.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646354578/portfolio/portraits/cm_i1m5fp.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646354579/portfolio/portraits/yc_zkwy86.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646354580/portfolio/portraits/nsak_rg7jfy.jpg",
    width: 4,
    height: 5,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646354578/portfolio/portraits/cr_cgaq0p.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646354579/portfolio/portraits/qx_yvy0vb.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646354580/portfolio/portraits/yn_bb8xls.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646354580/portfolio/portraits/ycks_ykcmcf.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646354578/portfolio/portraits/ak_wt7ejg.jpg",
    width: 4,
    height: 5,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646354580/portfolio/portraits/rp_c2zdtu.jpg",
    width: 1,
    height: 1,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646354578/portfolio/portraits/ms_ph4mkq.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646354577/portfolio/portraits/ej_h26z4w.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646356737/portfolio/portraits/rc_uucnxv.jpg",
    width: 3,
    height: 4,
  },
];
