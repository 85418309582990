export const familyPhotos = [
  // ***********************************************
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1714165299/portfolio/family/kv-4_dfanzh.jpg",
    width: 4,
    height: 5,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1714165198/portfolio/family/kv-2_cjpth3.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1714165300/portfolio/family/kv-5_whh5d4.jpg",
    width: 4,
    height: 5,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1714165298/portfolio/family/kv-3_gusngh.jpg",
    width: 5,
    height: 7,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1714165964/portfolio/family/kv-6_jpbipx.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1714165177/portfolio/family/kv-1_zdpblw.jpg",
    width: 2,
    height: 3,
  },
  // ***********************************************
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1695257334/portfolio/family/wf-4_uiwude.jpg",
    width: 5,
    height: 7,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1695257334/portfolio/family/wf-2_wjplm6.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1695257332/portfolio/family/wf-6_rue1ss.jpg",
    width: 5,
    height: 7,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1695257333/portfolio/family/wf-3_kqfypz.jpg",
    width: 4,
    height: 5,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1695257332/portfolio/family/wf-5_lmfbtj.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1695258310/portfolio/family/wf-1_ubfpwg.jpg",
    width: 3,
    height: 4,
  },
  // ***********************************************
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1714171690/portfolio/family/cf-3_rsulcy.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1714171690/portfolio/family/cf-2_uytqug.jpg",
    width: 4,
    height: 5,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1714171691/portfolio/family/cf-4_uwi9rc.jpg",
    width: 5,
    height: 7,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1714171690/portfolio/family/cf-1_d8dnvl.jpg",
    width: 3,
    height: 4,
  },
  // ***********************************************
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1714172298/portfolio/family/lf-1_npt8bh.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1714172301/portfolio/family/lf-2_xbzi48.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1714172299/portfolio/family/lf-3_jhkh59.jpg",
    width: 4,
    height: 5,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1714172299/portfolio/family/lf-4_xycnis.jpg",
    width: 5,
    height: 7,
  },
  // ***********************************************
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1714173169/portfolio/family/kfam-3_gcz4b8.jpg",
    width: 7,
    height: 5,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1714173168/portfolio/family/kfam-4_nhqvnt.jpg",
    width: 4,
    height: 5,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1714173168/portfolio/family/kfam-2_r81i2t.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1714173169/portfolio/family/kfam-1_nypvcr.jpg",
    width: 4,
    height: 3,
  },
  // ***********************************************
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1696940840/portfolio/family/nf-5_q71j6c.jpg",
    width: 5,
    height: 7,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1696940840/portfolio/family/nf-4_azgadk.jpg",
    width: 4,
    height: 5,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1696940840/portfolio/family/nf-6_qqdboo.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1696940840/portfolio/family/nf-3_qyefgu.jpg",
    width: 4,
    height: 5,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1696940840/portfolio/family/nf-2_zhtrgv.jpg",
    width: 5,
    height: 7,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1697118144/portfolio/family/nf-9_hmm2ub.jpg",
    width: 2,
    height: 3,
  },
];
