import App from "./App";
import React from "react";

import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Home from "./components/Home";
// import ErrorPage from "./components/ErrorPage";
import PhotoCategory from "./components/Photo/PhotoCategory";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    // errorElement: <ErrorPage />,
    children: [
      { path: "", element: <Home /> },
      { path: "couples", element: <PhotoCategory category="couples" /> },
      { path: "graduation", element: <PhotoCategory category="graduation" /> },
      { path: "family", element: <PhotoCategory category="family" /> },
      { path: "portraits", element: <PhotoCategory category="portraits" /> },
    ],
  },
]);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
