import React, { useState, useEffect } from "react";
import {
  motion,
  useVelocity,
  useScroll,
  useMotionValueEvent,
} from "framer-motion";
import { NavLink, useLocation } from "react-router-dom";
import { useWidth } from "../utils/hooks";
import logo from "../assets/logo.png";

const links = ["couples", "family", "graduation", "portraits"];
const onInstagramClick = () => {
  window.open("https://www.instagram.com/nwc.photo/", "_blank");
};

const Links = () => {
  return (
    <div className="nav-links">
      {links.map((link) => (
        <NavLink to={link} key={link}>
          {link}
        </NavLink>
      ))}
      {/* <div className="divider no-user-select"></div>
      <i className="bi bi-instagram" onClick={onInstagramClick} /> */}
    </div>
  );
};

const MobileLinks = ({ open }) => {
  const fade = {
    show: { opacity: 1, display: "flex", transition: { duration: 0.3 } },
    hide: { opacity: 0, transitionEnd: { display: "none" } },
  };

  const verticalSlide = {
    show: { y: 0, opacity: 1, transition: { duration: 0.6 } },
    hide: { y: "-20px", opacity: 0, transition: { duration: 1 } },
  };

  return (
    <motion.div
      className="nav-links mobile-nav-links"
      variants={fade}
      animate={open ? "show" : "hide"}
      initial="hide"
    >
      <motion.div
        className="links-motion-wrapper"
        variants={verticalSlide}
        animate={open ? "show" : "hide"}
      >
        {links.map((link) => (
          <NavLink to={link} key={link}>
            {link}
          </NavLink>
        ))}
        {/* <div className="divider no-user-select"></div>
        <i className="bi bi-instagram" onClick={onInstagramClick} /> */}
      </motion.div>
    </motion.div>
  );
};

const Navbar = ({ showNav, setShowNav }) => {
  const width = useWidth();
  const location = useLocation();
  const { scrollY } = useScroll();
  const velocity = useVelocity(scrollY);

  const [mobileNav, setMobileNav] = useState(width < 900);
  const [open, setOpen] = useState(false);
  const [hideOnScroll, setHideOnScroll] = useState(false);

  const onToggleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setMobileNav(width < 900);
  }, [width]);

  useEffect(() => {
    setOpen(false);
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    document.querySelector("body").classList.toggle("no-scroll", open);
  }, [open]);

  useMotionValueEvent(velocity, "change", (val) => {
    if (Math.abs(val) > 200) {
      setHideOnScroll(val > 0 && scrollY.current > 20);
    }
  });

  const scrollingNav = {
    show: { top: 0, transition: { duration: 0.5 } },
    hide: { top: "-100px", transition: { duration: 0.4, delay: 0.1 } },
  };

  return (
    <motion.nav
      variants={scrollingNav}
      animate={hideOnScroll ? "hide" : "show"}
    >
      <div className="navbar">
        <NavLink to="/" className="nav-logo page-title">
          <div className="logo-border">
            <img src={logo} alt="logo" />
          </div>
          <span>natalie chen</span>
        </NavLink>
        {mobileNav ? <MobileLinks open={open} /> : <Links />}
        <div className={`menu-close-icon ${mobileNav ? "" : "hide"}`}>
          <div
            className={`icon ${open ? "active" : "not-active"}`}
            onClick={onToggleClick}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </motion.nav>
  );
};

export default Navbar;
