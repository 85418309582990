import React, { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useWidth } from "../utils/hooks";
import ParallaxImageHeader from "./ParallaxImageHeader";
import profile from "../assets/profile.jpg";
import Gallery from "react-photo-gallery";
import MotionImage from "./Photo/MotionImage";
import { featuredPhotos } from "../data/cloudinary/featured";

const variants = {
  vertical: {
    y: "25%",
    opacity: 0,
  },
  image: {
    x: "-25%",
    opacity: 0,
  },
  text: {
    x: "25%",
    opacity: 0,
  },
  show: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: {
      duration: 1.5,
    },
  },
};

const CategoryCard = ({ category }) => {
  const { name, image } = category;

  return (
    <Link to={name}>
      <motion.div className="category-card">
        <div className="image-wrapper">
          <div
            className="image"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
        </div>
        <h5>{name}</h5>
      </motion.div>
    </Link>
  );
};

const Home = () => {
  const width = useWidth();
  const data = {
    cover:
      "https://res.cloudinary.com/dixemyxo3/image/upload/v1682994105/portfolio/5D4_0857_a8o2m3.jpg",
    mobileCover:
      "https://res.cloudinary.com/dixemyxo3/image/upload/v1682994106/portfolio/5D4_0857-2_du1qlk.jpg",
    mobileWidth: 1100,
    title: "natalie chen photography",
    subtitle: (
      <span>
        <i className="bi bi-geo-alt" />
        bay area, CA
      </span>
    ),
  };

  const imageRenderer = useCallback(
    ({ index, left, top, key, photo, margin, direction }) => (
      <MotionImage
        selected={false}
        key={key}
        margin={margin}
        direction={direction}
        index={index}
        photo={photo}
        left={left}
        top={top}
      />
    ),
    []
  );

  return (
    <div className="home">
      <ParallaxImageHeader data={data} />
      <div className="about">
        <motion.section
          variants={variants}
          initial={width < 900 ? "vertical" : "text"}
          whileInView="show"
          viewport={{ once: true, amount: "50px" }}
        >
          <h1 className="page-title">hi there, i'm natalie!</h1>
          <div className="text">
            <p>
              I first got into photography in high school as a way to document
              memories from events like sports games, prom askings, and spirit
              weeks. When I saw how happy it made people to receive their
              pictures, it inspired me to continue capturing moments that could
              be treasured for a lifetime.
            </p>
            <p>
              Whether you're looking to celebrate a huge milestone or simply
              capture a snapshot of this particular moment in your life, I'd
              love to work with you to get the perfect photos!
            </p>
          </div>
        </motion.section>
        <motion.img
          variants={variants}
          initial={width < 900 ? "vertical" : "image"}
          whileInView="show"
          viewport={{ once: true, amount: "50px" }}
          src={profile}
          alt="natalie"
        />
      </div>
      <div className="home-divider"></div>
      <div className="categories-wrapper">
        {featuredPhotos.map((category) => (
          <CategoryCard category={category} key={category.name} />
        ))}
      </div>
    </div>
  );
};

export default Home;
