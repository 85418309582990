export const featuredPhotos = [
  {
    name: "couples",
    image:
      "https://res.cloudinary.com/dixemyxo3/image/upload/v1682220087/portfolio/couples/cj-4_kyr2sb.jpg",
  },
  {
    name: "graduation",
    image:
      "https://res.cloudinary.com/dixemyxo3/image/upload/v1646259469/portfolio/grad/mk-4_bogxvk.jpg",
  },
  {
    name: "family",
    image:
      "https://res.cloudinary.com/dixemyxo3/image/upload/v1696940840/portfolio/family/nf-4_azgadk.jpg",
  },
  {
    name: "portraits",
    image:
      "https://res.cloudinary.com/dixemyxo3/image/upload/v1646354579/portfolio/portraits/yc_zkwy86.jpg",
  },
];
