export const couplesPhotos = [
  // ----------------------------------------------------
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1695256286/portfolio/couples/jb-4_g46xpp.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1695256284/portfolio/couples/jb-2_z8lqer.jpg",
    width: 9,
    height: 16,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1695256285/portfolio/couples/jb-5_andzhz.jpg",
    width: 4,
    height: 5,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1695256284/portfolio/couples/jb-1_ztwqnk.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1695256284/portfolio/couples/jb-3_wlf5np.jpg",
    width: 2,
    height: 3,
  },
  // ----------------------------------------------------
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646252179/portfolio/couples/bm-5_mjgt5d.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646252179/portfolio/couples/bm-4_glowiu.jpg",
    width: 10,
    height: 16,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646251129/portfolio/couples/bm-3_hp696a.jpg",
    width: 4,
    height: 5,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646251129/portfolio/couples/bm-1_y7j8lw.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646251129/portfolio/couples/bm-2_q7mcdu.jpg",
    width: 4,
    height: 3,
  },
  // ----------------------------------------------------
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1682220087/portfolio/couples/cj-2_gxkxhm.jpg",
    width: 4,
    height: 5,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1682220087/portfolio/couples/cj-4_kyr2sb.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1682220087/portfolio/couples/cj-3_zyu7ug.jpg",
    width: 5,
    height: 7,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1682220087/portfolio/couples/cj-5_fpxehi.jpg",
    width: 2,
    height: 3,
  },
  // ----------------------------------------------------
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1650570963/portfolio/couples/ka-2_jmlbmh.jpg",
    width: 4,
    height: 5,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646252179/portfolio/couples/ka-1_bzodxr.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646252179/portfolio/couples/ka-3_pd588r.jpg",
    width: 4,
    height: 5,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646251130/portfolio/couples/ka-4_kozfrk.jpg",
    width: 5,
    height: 4,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646251130/portfolio/couples/ka-5_kfnkom.jpg",
    width: 2,
    height: 3,
  },
  // ----------------------------------------------------
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646252179/portfolio/couples/km-3_vaem90.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646251130/portfolio/couples/km-2_vpryhd.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1650571083/portfolio/couples/km-4_yjitag.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646251131/portfolio/couples/km-5_hl0u2b.jpg",
    width: 1,
    height: 1,
  },
  // ----------------------------------------------------
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1664403904/portfolio/couples/cg-7_emlfrp.jpg",
    width: 5,
    height: 7,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1664403622/portfolio/couples/cg-5_vh8rok.jpg",
    width: 4,
    height: 5,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1664403622/portfolio/couples/cg-6_e7oioi.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1664403904/portfolio/couples/cg-8_ca4ui4.jpg",
    width: 1,
    height: 1,
  },
  // ----------------------------------------------------
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1714174343/portfolio/couples/dj-1_swrgjn.jpg",
    width: 5,
    height: 4,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1714174343/portfolio/couples/dj-3_rzailv.jpg",
    width: 5,
    height: 7,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1714174340/portfolio/couples/dj-2_qy8wus.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1714174687/portfolio/couples/dj-4_je3wxf.jpg",
    width: 2,
    height: 3,
  },
  // {
  //   src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1646357438/portfolio/couples/rset_jzzahd.jpg",
  //   width: 3,
  //   height: 4,
  // },
  // ----------------------------------------------------
  // KATHERINE & MICAH & DOG
  // {
  //   src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1682220076/portfolio/couples/km-8_ga5je0.jpg",
  //   width: 2,
  //   height: 3,
  // },
  // {
  //   src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1682220076/portfolio/couples/km-11_wjdvxu.jpg",
  //   width: 5,
  //   height: 7,
  // },
  // {
  //   src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1682220076/portfolio/couples/km-9_bu43pl.jpg",
  //   width: 3,
  //   height: 4,
  // },
  // {
  //   src: "https://res.cloudinary.com/dixemyxo3/image/upload/v1682220076/portfolio/couples/km-10_cxzuck.jpg",
  //   width: 1,
  //   height: 1,
  // },
];
