import React, { useCallback, useState } from "react";
import { useWidth } from "../utils/hooks";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { motion, useTransform, useScroll } from "framer-motion";

const ReturnToTop = () => {
  const { scrollYProgress } = useScroll();
  const width = useWidth();
  const opacity = useTransform(scrollYProgress, [0, 0.3], [0, 1]);

  const offset = width < 900 ? (width < 600 ? 65 : 80) : 100;

  return (
    <div className="return-to-top" style={{ bottom: offset, right: offset }}>
      <AnchorLink href="#main">
        <motion.i
          className="bi bi-arrow-up-circle"
          style={{ opacity: opacity }}
        />
      </AnchorLink>
    </div>
  );
};

export default ReturnToTop;
